import Inputmask from "../node_modules/inputmask/dist/inputmask.es6.js";

if (typeof(window.BalanceCheck) === "undefined") {
	window.BalanceCheck= function() {
		return {
			i18n: {},
			countryCode: null,
			languageCode: null,
			threadMetrixInitialized: false,
			sessionId: null,

			initialize: function initialize(options) {
				this.containerElement = document.querySelector('.js-balancecheck-' + options.widgetId);

				this.formElement = this.containerElement.querySelector('form');
				this.pinField = this.containerElement.querySelector('input.js-pin-input');
				this.submitButton = this.containerElement.querySelector('.js-balancecheck-submit');

				this.checkSuccessSection = this.containerElement.querySelector('.js-check-success');
				this.checkAnother = this.containerElement.querySelector('.js-check-another');
				this.checkErrorSection = this.containerElement.querySelector('.js-check-error');
				this.transactionSection = this.containerElement.querySelector('.js-transactions');

				this.pinStatusUrl = '/index.php?eID=balanceCheck';

				this.i18n = options.i18n;
				this.countryCode = options.countryCode;
				this.languageCode = options.languageCode;
				this.additionalParameters = options.additionalParameters;

				this.generateSessionId();
				this.initializeEventHandlers();
				this.maskPinField();
			},

			initializeEventHandlers: function () {
				let that = this;
				this.pinField.addEventListener('focus', function (e) {
					that.pinField.classList.remove('invalid');
					that.initializeThreadMetrix();
				});
				this.pinField.addEventListener('input', function (e) {
					that.validateUserInput();
				});
			},

			maskPinField: function () {
				Inputmask({
					'mask': '9999 9999 9999 9999',
					'showMaskOnHover': false
				}).mask(this.pinField);
			},

			countSubStr: function count (str, re) {
				return str.split(re).length - 1;
			},

			formatUserInput: function () {
				const { value, selectionStart } = this.pinField;
				const oldIndex = selectionStart - this.countSubStr(value.slice(0, selectionStart), /\D/g);
				const numeric = value.replace(/\D/g, '');
				const sanitized = numeric
					.replace(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4}).*/, '$1 $2 $3 $4')
					.trim();
				const newIndex = oldIndex + Math.floor((oldIndex + 2) / 4);

				this.pinField.value = sanitized;
				this.pinField.setSelectionRange(newIndex, newIndex);
			},

			validateUserInput: function() {
				this.submitButton.disabled = !this.isSyntacticallyValidPin(this.getPinFieldValue());
			},

			initializeThreadMetrix: function () {
				if (this.threadMetrixInitialized === false) {
					let uAgent = navigator.userAgent;
					if (uAgent.indexOf("PLAYSTATION") !== -1) {
						let threadMatrixContainer = this.containerElement.querySelector('.balancecheck-tm-container');
						threadMatrixContainer.style.width = '1px';
						threadMatrixContainer.style.height = '1px';
						threadMatrixContainer.style.overflow = 'hidden';
					}

					let script = document.createElement('script');
					script.type = 'text/javascript';
					if (script.readyState) {
						script.onreadystatechange = function () {
							if (script.readyState === 'loaded' || script.readyState === 'complete') {
								script.onreadystatechange = null;
								threatmetrix.da027qfC1p('paysafecard.com', '8bcyihmu', this.sessionId);
							}
						}.bind(this);
					} else {
						script.onload = function () {
							threatmetrix.da027qfC1p('tm.paysafecard.com', '8bcyihmu', this.sessionId);
						}.bind(this);
					}
					script.src = 'https://static.paysafecard.com/da027qfC1p.js';
					document.getElementsByTagName('head').item(0).appendChild(script);
					this.threadMetrixInitialized = true;
				}
			},

			showLoadingIndicator: function() {
				this.pinField.classList.remove('invalid');
				this.pinField.parentElement.classList.add("form__row__input--progress");
			},

			hideLoadingIndicator: function() {
				this.pinField.parentElement.classList.remove("form__row__input--progress");
			},

			getPinDetails: function (recaptchaToken) {
				this.showLoadingIndicator();
				this.checkSuccessSection.style.display = 'none';
				this.checkAnother.style.display = 'none';
				this.checkErrorSection.style.display = 'none';
				this.transactionSection.classList.remove('show');

				this.containerElement.querySelector('.js-trigger-transactions').classList.add('collapsed');
				this.containerElement.querySelector('.js-trigger-transactions').setAttribute('aria-expanded', 'false');

				if(!this.isVisibleInViewport(this.pinField)) {
					this.pinField.scrollIntoView({block: 'center', behavior: 'instant'});
				} 

				let pinFieldValue = this.getPinFieldValue();
				if (!this.isSyntacticallyValidPin(pinFieldValue)) {
					this.checkErrorSection.querySelector('.js-error-message').innerHTML = this.i18n['error_100'];
					this.checkErrorSection.style.display = 'block';
					grecaptcha.enterprise.reset();
					return;
				}

				let maskedPin = pinFieldValue.replace(/(\d{0,4})\D*(\d{0,4})\D*(\d{0,4})\D*(\d{0,4}).*/, '**** **** **** $4');
				this.containerElement.querySelector('.js-masked-pin').innerText = maskedPin;

				let httpRequest = new XMLHttpRequest();
				httpRequest.open("POST", this.pinStatusUrl);
				httpRequest.setRequestHeader("Content-Type", "application/json");
				httpRequest.send(JSON.stringify({
					pin: pinFieldValue,
					sid: this.sessionId,
					recaptchaResponse: recaptchaToken
				}));

				let that = this;
				httpRequest.onreadystatechange = function () {
					if (httpRequest.readyState === 4 && httpRequest.status === 200) {
						that.hideLoadingIndicator();

						let response = JSON.parse(this.responseText);
						if (response.status === 'success') {
							that.resetForm();

							const context = {
								data: response.data,
								i18n: that.i18n
							};

							if (that.countryCode.toLowerCase() === 'us') {
								if (context.data.currencyCode && context.data.currencyCode.toLowerCase() === 'usd') {
									context.data.currencyCode = '$';
								}
								if (context.data.card.currencyCode && context.data.card.currencyCode.toLowerCase() === 'usd') {
									context.data.card.currencyCode = '$';
								}
								if (context.data.transactions) {
									context.data.transactions.forEach(function (transaction) {
										if (transaction.currencyCode.toLowerCase() === 'usd') {
											transaction.currencyCode = '$';
										}
									});
								}
							}

							that.containerElement.querySelector('.js-balance-currency').innerText = response.data.currencyCode;
							that.containerElement.querySelector('.js-balance-amount').innerText = response.data.availableBalance;
							that.containerElement.querySelector('.js-facevalue-amount').innerText = response.data.faceValue;
							that.containerElement.querySelector('.js-facevalue-currency').innerText = response.data.currencyCode;

							if (context.data.transactions && context.data.transactions.length > 0) {
								that.containerElement.querySelector('.js-trigger-transactions').style.visibility = 'visible';
								that.containerElement.classList.add('show-balance-details');

								let transactionList = '';
								context.data.transactions.forEach(function (transaction) {
									let rand = Math.floor(Math.random() * 1000000) + 1;
									transactionList += '<tr>';
									transactionList += '<td><a href="#js-transaction-' + rand + '" data-bs-toggle="collapse" class="js-transaction__date-details" aria-expanded="false">' + transaction.date + '</a></td>';
									transactionList += '<td>' + transaction.merchant.displayName + '</td>';
									transactionList += '<td>' + transaction.debitedAmount  + ' ' + transaction.currencyCode + '</td>';
									transactionList += '</tr>';
									transactionList += '<tr id="js-transaction-' + rand + '" class="collapse-row collapse"><td colspan="3"><table><tbody>';
									transactionList += '<tr><th>';
									transactionList += that.i18n['transactionlist_innertitle_transaction_id'];
									transactionList += '</th><td>';
									transactionList += transaction.merchantTransactionId;
									transactionList += '</td></tr>';
									transactionList += '<tr><th>';
									transactionList += that.i18n['transactionlist_innertitle_merchant_id'];
									transactionList += '</th><td>';
									transactionList += transaction.merchant.lifnr;
									transactionList += '</td></tr>';
									transactionList += '<tr><th>';
									transactionList += that.i18n['transactionlist_innertitle_rate'];
									transactionList += '</th><td>';
									transactionList += transaction.exchangeRateWithFees;
									transactionList += '</td></tr>';
									transactionList += '<tr><th>';
									transactionList += that.i18n['transactionlist_innertitle_status'];
									transactionList += '</th><td>';
									transactionList += that.i18n['transactionlist_status_' + transaction.transactionState];
									transactionList += '</td></tr>';
									transactionList += '<tr><th>';
									transactionList += that.i18n['transactionlist_innertitle_status_detail'];
									transactionList += '</th><td>';
									transactionList += that.i18n['transactionlist_status_detail_' + transaction.transactionState];
									transactionList += '</td></tr>';
									transactionList += '</table></td></tr>';
								});
								that.getTransactionTable().innerHTML = transactionList;

								let transactionListSmall = '';
								context.data.transactions.forEach(function (transaction) {
									let rand = Math.floor(Math.random() * 1000000) + 1;
									transactionListSmall += '<div id="js-transaction-' + rand + '-sm" class="collapse-row collapse">';
									transactionListSmall += '<h4>' + that.i18n['transactionlist_innertitle_transaction_id'] + '</h4>';
									transactionListSmall += '<p>' + transaction.merchantTransactionId + '</p>';
									transactionListSmall += '<h4>' + that.i18n['transactionlist_innertitle_merchant_id'] + '</h4>';
									transactionListSmall += '<p>' + transaction.merchant.lifnr + '</p>';
									transactionListSmall += '<h4>' + that.i18n['transactionlist_innertitle_rate'] + '</h4>';
									transactionListSmall += '<p>' + transaction.exchangeRateWithFees + '</p>';
									transactionListSmall += '<h4>' + that.i18n['transactionlist_innertitle_status'] + '</h4>';
									transactionListSmall += '<p>' + that.i18n['transactionlist_status_' + transaction.transactionState] + '</p>';
									transactionListSmall += '<h4>' + that.i18n['transactionlist_innertitle_status_detail'] + '</h4>';
									transactionListSmall += '<p>' + that.i18n['transactionlist_status_detail_' + transaction.transactionState] + '</p>';
									transactionListSmall += '</div>';

									let transactionRowSmall = that.getFirstNonTextChild(that.getTransactionRowSmallTemplate()).cloneNode(true);

									transactionRowSmall.querySelector('.js-date').innerHTML = '<a href="#js-transaction-' + rand + '-sm" data-bs-toggle="collapse" class="js-transaction__date-details" aria-expanded="false">' + transaction.date + '</a>';
									transactionRowSmall.querySelector('.js-webshop').innerText = transaction.merchant.displayName;
									transactionRowSmall.querySelector('.js-debited-amount').innerText = transaction.debitedAmount + ' ' + transaction.currencyCode;
									transactionRowSmall.querySelector('.js-date__table-details').innerHTML = transactionListSmall;
									transactionListSmall = '';

									that.getTransactionTableSmall().append(transactionRowSmall);
								});
							} else {
								that.containerElement.querySelector('.js-trigger-transactions').style.visibility = 'hidden';
								that.containerElement.classList.remove('show-balance-details');
							}

							that.checkSuccessSection.style.display = 'block';
							that.checkAnother.style.display = 'block'; 
						} else {
							that.pinField.classList.add('invalid');
							if (response.data.statusCode) {
								that.checkErrorSection.querySelector('.js-error-message').innerHTML = that.i18n['error_' + response.data.statusCode];
							} else {
								that.checkErrorSection.querySelector('.js-error-message').innerHTML = that.i18n['error_400'];
							}
							that.checkErrorSection.style.display = 'block';
						}
					}
				};

				grecaptcha.enterprise.reset();
			},

			isSyntacticallyValidPin: function (value) {
				return /^\d{16}$/.test(value.replace(/[^\d]/g, ''));
			},

			getPinFieldValue: function () {
				return this.pinField.value;
			},

			setPinFieldValue: function (newValue) {
				return this.pinField.value = newValue;
			},

			resetForm: function () {
				this.formElement.reset();
				this.submitButton.disabled = true;
				this.clearTransactionList();
			},

			getTransactionTable: function () {
				return this.containerElement.querySelector('.js-transaction-table');
			},

			getTransactionTableSmall: function () {
				return this.containerElement.querySelector('.js-transaction-table-sm');
			},

			getTransactionRowSmallTemplate: function () {
				return this.containerElement.querySelector('.js-transaction-row-sm-template');
			},

			clearTransactionList: function() {
				let parent;
				parent =  this.getTransactionTable();
				while (parent.firstChild) {
					parent.removeChild(parent.firstChild);
				}
				parent = this.getTransactionTableSmall();
				while (parent.firstChild) {
					parent.removeChild(parent.firstChild);
				}
			},

			generateSessionId: function() {
				function s4() {
					return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
				}
				this.sessionId = s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
				return this.sessionId;
			},

			getFirstNonTextChild: function (el) {
				let firstChild = el.firstChild;
				while(firstChild != null && firstChild.nodeType === 3){
					firstChild = firstChild.nextSibling;
				}
				return firstChild;
			},

			isVisibleInViewport: function (el) {
				const rect = el.getBoundingClientRect();
				return (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= (window.innerWidth || document.documentElement.clientWidth)
				);
			}
		};
	}
}
